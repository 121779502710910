<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :headers="headers"
          :search="search"
          :items="violations"
          :page="page"
          :status="status"
          :no-add-button="noAddButton"
          :filter-status="filterStatus"
          :total-pages.sync="totalPages"
          :is-loading="isLoadingData"
          title="Pelanggaran Pegawai"
          subtitle="Pegawai yang melakukan pelanggaran"
          @live-search="searchHandler"
          @filter-status="handleFilterStatus"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'ViolationEmployee',
  components: {
    DataTablePagination,
    MainCard,
    SkeletonLoaderTable,
  },
  data() {
    return {
      dialog: '',
      search: '',
      modalDialog: false,
      confirmDialog: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      noAddButton: true,
      filterStatus: true,
      page: 1,
      totalPages: 1,
      totalItems: 1,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Tanggal', value: 'violation_date' },
        { text: 'Nama Pegawai', value: 'employee.name' },
        { text: 'Status', value: 'employee.is_active' },
        { text: 'Pelanggaran', value: 'violation.name' },
        { text: 'Deskripsi', value: 'description' },
        { text: 'Poin', value: 'violation.point' },
        { text: 'Tindak Lanjut', value: 'follow_up' },
      ],
      violations: [],
      school_uuid: '',
      status: [
        {
          id: 1,
          name: 'Active',
        },
        {
          id: 0,
          name: 'Not Active',
        },
      ],
    }
  },
  mounted() {
    this.getSchoolUuid()
    this.listViolation()
  },
  methods: {
    handleFilterStatus(status) {
      if (status !== null) return this.listViolation({ status })

      return this.listViolation()
    },

    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: "You don't have access to school",
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async listViolation(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('violationemployee', {
        ...params,
        school_uuid: this.school_uuid,
        page: this.page,
      }).then(
        ({ data }) => {
          this.violations = data.data.map((violations, index) => ({
            ...violations,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },
    searchHandler(violation) {
      this.listViolation({ search: violation })
    },
  },
}
</script>

<style>
</style>
