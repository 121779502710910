<template>
  <MainCard>
    <template v-slot:body>
      <v-tabs
        v-model="tab"
        show-arrows
        fixed-tabs
      >
        <v-tab
          v-for="tabItem in tabs"
          :key="tabItem.title"
        >
          <span>{{ tabItem.title }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <violation-student></violation-student>
        </v-tab-item>
        <v-tab-item>
          <violation-employee></violation-employee>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </MainCard>
</template>

<script>
import MainCard from '@/views/components/MainCard.vue'
import ViolationEmployee from './ViolationEmployee.vue'
import ViolationStudent from './ViolationStudent.vue'

export default {
  name: 'Violation',
  components: {
    ViolationStudent,
    ViolationEmployee,
    MainCard,
  },
  data() {
    return {
      tab: '',
      tabs: [{ title: 'Pelanggaran Siswa' }, { title: 'Pelanggaran Pegawai' }],
    }
  },
}
</script>

<style>
</style>
