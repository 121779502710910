<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :search="search"
          :is-loading="isLoadingData"
          :no-add-button="noAddButton"
          :filter-class="true"
          :classes="classes"
          title="Pelanggaran Siswa"
          subtitle="Siswa yang melakukan pelanggaran"
          :items="studentViolations"
          :total-pages.sync="totalPages"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
          @filter-classes="handleFilterClass"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'ViolationStudent',
  components: {
    DataTablePagination,
    MainCard,
    SkeletonLoaderTable,
  },
  data() {
    return {
      noAddButton: true,
      skeleton: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        types: {},
      },
      page: 1,
      search: '',
      totalItems: 0,
      totalPages: 0,
      width: 600,
      modalDialog: false,
      confirmDialog: false,
      dialog: null,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Tanggal', value: 'violation_date' },
        { text: 'Nama Siswa', value: 'student.full_name' },
        { text: 'Pelanggaran', value: 'violation.name' },
        { text: 'Deskripsi', value: 'description' },
        { text: 'Poin', value: 'violation.point' },
        { text: 'Tindak Lanjut', value: 'follow_up' },
      ],
      students: [],
      violations: [],
      studentViolations: [],
      studentViolation: {
        uuid: '',
        student_uuid: '',
        violation_uuid: '',
        school_uuid: '',
        employee_uuid: '',
        violation_date: '',
        description: '',
        follow_up: '',
      },
      classes: [],
      date_menu: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      school: [],
      school_uuid: '',
      user_uuid: '',
    }
  },
  watch: {
    page: {
      handler() {
        this.listViolationStudent()
      },
    },
  },
  async mounted() {
    this.getSchoolUuid()
    await this.listViolationStudent()
    await this.getListClass()
  },
  methods: {
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: "You don't have access to school",
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async handleFilterClass(uuid) {
      if (uuid === null) {
        await this.listViolationStudent()

        return
      }
      await this.listViolationStudent({ class_uuid: uuid })
    },
    async getListClass() {
      await this.$services.ApiServices.list('class', { school_uuid: this.school_uuid, per_page: 'all' }).then(
        ({ data }) => {
          data.data.forEach(item => this.classes.push(item))
        },
        err => console.error(err),
      )
    },
    async listViolationStudent(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('violationstudent', {
        ...params,
        school: this.school_uuid,
        page: this.page,
      }).then(
        ({ data }) => {
          this.studentViolations = data.data.map((studentViolations, index) => ({
            ...studentViolations,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    searchHandler(studentViolation) {
      this.listViolationStudent({ search: studentViolation })
    },
  },
}
</script>

<style scoped>
.v-menu__content::v-deep {
  min-width: 0 !important;
}
</style>
